import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLoggedIn } from 'bv-hooks';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { replaceFragment } from 'bv-helpers/location';
import WithTwoFaModal from 'TwoFactorAuth/hocs/with_twofa_modal';
import { doGetTwoFaMethods, setReloadTwoFaStatus } from 'TwoFactorAuth/duck';
import { continueLogin } from './helpers/common';
import CodeFeedbackView from './code_feedback_view';

const CodeFeedbackContainer = ({
  twofaMethodsData,
  loadedMethods,
  getTwoFaMethods,
  reloadTwoFaStatus,
}) => {
  const loggedIn = useLoggedIn();

  useEffect(() => {
    getTwoFaMethods();
  }, []);

  const onRedirect = !loggedIn ? continueLogin : () => {
    reloadTwoFaStatus();
    replaceFragment('/preferences/twofactorauth');
  };

  return (
    <CodeFeedbackView
      loadedMethods={loadedMethods}
      twofaMethodsData={twofaMethodsData}
      isLogin={!loggedIn}
      onRedirect={onRedirect}
    />
  );
};

CodeFeedbackContainer.propTypes = {
  twofaMethodsData: PropTypes.shape({
    currentMethod: PropTypes.string,
    availableMethods: PropTypes.arrayOf(PropTypes.string),
    contactUsUrl: PropTypes.string,
  }).isRequired,
  loadedMethods: PropTypes.bool.isRequired,
  getTwoFaMethods: PropTypes.func.isRequired,
  reloadTwoFaStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  twofaMethodsData: state.twoFa.twofaMethodsData,
  loadedMethods: state.twoFa.loadedMethods,
});

const mapDispatchToProps = (dispatch) => ({
  getTwoFaMethods: compose(dispatch, doGetTwoFaMethods),
  reloadTwoFaStatus: compose(dispatch, setReloadTwoFaStatus),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithTwoFaModal(CodeFeedbackContainer, { tKey: 'javascript.twofa.navigation_title.mobile_sms_verification' }));
