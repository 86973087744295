import App from './app';

import reducer from './duck';

const { addReducers } = window.reduxState;

addReducers({
  twoFa: reducer,
});

export default App;
