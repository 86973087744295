import PropTypes from 'prop-types';
import { Route as OriginalRoute } from 'react-router-dom';
import { FeatureGuardComponent } from 'bv-components';

const Route = ({ feature, children, ...rest }) => (
  <OriginalRoute {...rest}>
    {feature ? (
      <FeatureGuardComponent feature={feature}>
        {children}
      </FeatureGuardComponent>
    ) : children}
  </OriginalRoute>
);

Route.defaultProps = {
  feature: undefined,
};

Route.propTypes = {
  feature: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Route;
