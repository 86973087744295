import PropTypes from 'prop-types';
import { DotContainer } from 'SharedComponents/send_code_from_sms';

const Login = ({ codeValue }) => (
  <div className="twofa-code__container twofa__container">
    <DotContainer code={codeValue} status="success" />
    <div className="twofa-code__input-container">
      <input
        disabled="disabled"
        value={codeValue}
        className="twofa-code__code-input"
        type="number"
      />
    </div>
    <div className="twofa-code__success-big-icon" />
  </div>
);

Login.propTypes = {
  codeValue: PropTypes.string,
};

Login.defaultProps = {
  codeValue: '',
};

export default Login;
