import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import PropTypes from 'prop-types';
import { Spinner, Button } from 'bv-components';
import { t } from 'bv-i18n';
import WithTwoFaModal from 'TwoFactorAuth/hocs/with_twofa_modal';
import { doGetTwoFaAppInfo, doGetTwoFaQRCode } from 'TwoFactorAuth/duck';
import Scan from './scan';

const App = ({
  loadedAppInfo, loadingAppInfo, getAppInfo, appInfo,
  loadedQrCode, loadingQrCode, getQrCode, qrCode,
}) => {
  const [showScan, setShowScan] = useState(false);

  useEffect(() => {
    if (!loadedAppInfo) getAppInfo();
  }, []);

  if (loadingAppInfo || loadingQrCode) return (<Spinner />);

  const {
    brand, name, url,
  } = appInfo;

  const download = () => {
    window.location.href = url;
  };

  const moveToScanPage = () => {
    if (!loadedQrCode) getQrCode();
    setShowScan(true);
  };

  if (showScan) {
    const { secret, qr, email } = qrCode;

    return (
      <Scan
        secret={secret}
        qr={qr}
        email={email}
      />
    );
  }

  return (
    <div className="bvs-card twofa__container twofa-totp-intro">
      <div className="twofa-totp-intro-header">
        <div className="twofa-totp-intro__icon" />
        <p className="twofa-totp-intro__intro-text">
          {t('javascript.twofa.settings.totp.intro.header')}
        </p>
      </div>
      <hr />
      {brand
        && (
          <div className="twofa-totp-intro-info">
            <span className="bvs-icon is-info twofa-totp-intro-info__icon" />
            <span className="twofa-totp-intro-info__text">
              <p>{t('javascript.twofa.settings.totp.intro.info.recommendation', { brand, app: name })}</p>
              <p>{t('javascript.twofa.settings.totp.intro.info.instructions')}</p>
            </span>
          </div>
        )}
      {url
        && (
          <Button
            className="twofa-totp-intro__button-download"
            secondary
            inverse
            label={t('javascript.twofa.settings.download_button', { app: name })}
            onClick={download}
          />
        )}
      <Button
        className="twofa-totp-intro__button-continue"
        primary
        label={t('javascript.twofa.settings.continue_button')}
        onClick={() => moveToScanPage()}
      />
    </div>
  );
};

App.propTypes = {
  loadingAppInfo: PropTypes.bool.isRequired,
  loadedAppInfo: PropTypes.bool.isRequired,
  getAppInfo: PropTypes.func.isRequired,
  appInfo: PropTypes.instanceOf(Object),
  loadingQrCode: PropTypes.bool.isRequired,
  loadedQrCode: PropTypes.bool.isRequired,
  getQrCode: PropTypes.func.isRequired,
  qrCode: PropTypes.instanceOf(Object),
};

App.defaultProps = {
  appInfo: {},
  qrCode: {},
};

const mapStateToProps = (state) => ({
  qrCode: state.twoFa.qrCode,
  loadingAppInfo: state.twoFa.loadingAppInfo,
  loadedAppInfo: state.twoFa.loadedAppInfo,
  loadingQrCode: state.twoFa.loadingQrCode,
  loadedQrCode: state.twoFa.loadedQrCode,
});

const mapDispatchToProps = (dispatch) => ({
  getAppInfo: compose(dispatch, doGetTwoFaAppInfo),
  getQrCode: compose(dispatch, doGetTwoFaQRCode),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithTwoFaModal(App, { tKey: 'javascript.twofa.navigation_title.settings' }));
