import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const ChooseLink = ({ login, toggleChooser }) => {
  if (!login) {
    return null;
  }
  return (
    <div className="send-verify-code__another-way">
      <span
        className="bvs-link"
        onClick={toggleChooser}
      >
        {t('javascript.twofa.settings.try_another_way.title')}
      </span>
    </div>
  );
};

ChooseLink.propTypes = {
  login: PropTypes.bool.isRequired,
  toggleChooser: PropTypes.func.isRequired,
};

export default ChooseLink;
