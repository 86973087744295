import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { t } from 'bv-i18n';
import WithTwoFaModal from 'TwoFactorAuth/hocs/with_twofa_modal';
import { getEnabledTotp, getEnabledSms } from 'TwoFactorAuth/selectors';
import Totp from './totp';
import Sms from './sms';

const Home = ({
  enabledTotp, enabledSms, recommendedMethod,
}) => {
  const history = useHistory();
  return (
    <div className="bvs-card twofa-enable">
      <div className="twofa-enable__icon" />
      <div className="bvs-h3 twofa-enable__title">
        {t('javascript.twofa.settings.enable_method.title')}
      </div>
      <div className="twofa-enable__methods">
        {enabledTotp != null && (
          <Totp enableCb={() => history.push('/preferences/twofactorauth/enable/totp')} recommended={recommendedMethod === 'totp'} />
        )}
        {enabledSms != null && (
          <Sms enableCb={() => history.push('/preferences/twofactorauth/enable/sms')} recommended={recommendedMethod === 'sms'} />
        )}
      </div>
    </div>
  );
};

Home.propTypes = {
  recommendedMethod: PropTypes.string.isRequired,
  enabledTotp: PropTypes.bool.isRequired,
  enabledSms: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  recommendedMethod: state.twoFa.recommendation,
  enabledTotp: getEnabledTotp(state),
  enabledSms: getEnabledSms(state),
});

export default connect(mapStateToProps)(WithTwoFaModal(Home, { tKey: 'javascript.twofa.navigation_title.settings' }));
