import PropTypes from 'prop-types';
import Login from './login';
import Enable from './enable';

const SuccessFeedback = ({
  login, method, redirect, codeValue, brand,
}) => (
  <div className="bvs-card twofa-code-success">
    <div className="twofa-header">
      <div className="twofa-header__icon" />
    </div>
    <hr />
    {
        login ? (
          <Login
            codeValue={codeValue}
          />
        ) : (
          <Enable
            method={method}
            redirect={redirect}
            brand={brand}
          />
        )
      }
  </div>
);

SuccessFeedback.propTypes = {
  login: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  method: PropTypes.string.isRequired,
  codeValue: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
};

export default SuccessFeedback;
