import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';

const Enable = ({ method, redirect, brand }) => (
  <>
    <div className="twofa-code-success-enable">
      <div className="twofa-code__success-bigger-icon" />
      <p className="twofa-code-success-enable__icon-text">{t('javascript.twofa.settings.success.icon_text')}</p>
      {method === 'totp' && <p>{t('javascript.twofa.settings.success.totp.description', { brand })}</p>}
      {method === 'totp' && <p>{t('javascript.twofa.settings.success.totp.instructions')}</p>}
    </div>
    <Button
      className="twofa-code-success-enable__button"
      primary
      label={t('javascript.twofa.settings.continue_button')}
      onClick={redirect}
    />
  </>
);

Enable.propTypes = {
  method: PropTypes.string.isRequired,
  redirect: PropTypes.func.isRequired,
  brand: PropTypes.string.isRequired,
};

export default Enable;
