import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const Description = ({ enabled }) => (
  <>
    <p>{t('javascript.twofa.description')}</p>
    {!enabled
    && <p>{t('javascript.twofa.enable_description')}</p> }
  </>
);

Description.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default Description;
