import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import Method from './method';

const Totp = ({ enableCb, recommended }) => (
  <Method
    title={t('javascript.twofa.settings.enable_method.totp.title')}
    topics={[
      t('javascript.twofa.settings.enable_method.totp.reasons.first'),
      t('javascript.twofa.settings.enable_method.totp.reasons.second'),
      t('javascript.twofa.settings.enable_method.totp.reasons.third'),
    ]}
    enableCb={enableCb}
    iconClass="twofa-enable-method__icon--totp"
    recommended={recommended}
  />
);

Totp.propTypes = {
  enableCb: PropTypes.func.isRequired,
  recommended: PropTypes.bool.isRequired,
};

export default Totp;
