import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { t } from 'bv-i18n';
import { setVerifiedPassword as doSetVerifiedPassword } from 'TwoFactorAuth/duck';
import { checkPassword } from 'TwoFactorAuth/api';
import Form from './form_view';

const FormContainer = ({ setVerifiedPassword }) => {
  const onSubmit = async ({ password }) => {
    const result = await checkPassword(password);
    if (result && result.success) {
      setVerifiedPassword();
      return undefined;
    }

    if (result && result.success === false && result.wrongPassword === true) {
      return { password: t('javascript.twofa.error.invalid_password') };
    }

    return { password: t('javascript.twofa.error.general') };
  };

  return (
    <Form
      onSubmit={onSubmit}
    />
  );
};

FormContainer.propTypes = {
  setVerifiedPassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setVerifiedPassword: compose(dispatch, doSetVerifiedPassword),
});

export default connect(null, mapDispatchToProps)(FormContainer);
