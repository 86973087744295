import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import Method from './method';

const Sms = ({ enableCb, recommended }) => (
  <Method
    title={t('javascript.twofa.settings.enable_method.sms.title')}
    topics={[
      t('javascript.twofa.settings.enable_method.sms.reasons.first'),
      t('javascript.twofa.settings.enable_method.sms.reasons.second'),
    ]}
    enableCb={enableCb}
    iconClass="twofa-enable-method__icon--sms"
    recommended={recommended}
  />
);

Sms.propTypes = {
  enableCb: PropTypes.func.isRequired,
  recommended: PropTypes.bool.isRequired,
};

export default Sms;
