import { t } from 'bv-i18n';
import PropTypes from 'prop-types';
import { PhoneNumber } from 'registration-common/components';
import { Form, Field } from 'react-final-form';
import { SendCodeSpinner } from 'SharedComponents/send_code_from_sms';
import { Button } from 'bv-components';

const FormView = ({
  originalNumber,
  dialCodes,
  hasLoadedOriginalData,
  onSubmit,
  onValidate,
}) => (
  <Form
    id="register-phone-number-form"
    onSubmit={onSubmit}
    initialValues={{ phone_number: originalNumber }}
    validate={onValidate}
  >
    {({ handleSubmit, validating, submitting }) => {
      const disabled = !hasLoadedOriginalData || validating || submitting;

      return (
        <div className="bvs-card register-phone-number-container">
          { disabled && <SendCodeSpinner /> }
          <form className="register-phone-number-container__header">
            <div className="register-phone-number-container__phone-lock-icon" />
            <p className="register-phone-number-container__twofactor-description">
              {t('javascript.twofa.mobile_sms_verification')}
            </p>
            <Field
              name="phone_number"
              component={PhoneNumber}
              label={t('javascript.twofa.settings.phone_number')}
              countries={dialCodes}
              initialValue={originalNumber}
              disabled={disabled}
            />
            <Button
              type="submit"
              className="bvs-button is-primary phone-number__submit-button"
              id="submit_phone_number_button"
              onClick={handleSubmit}
              disabled={disabled}
            >
              {t('javascript.twofa.settings.phone_number_button')}
            </Button>
          </form>
        </div>
      );
    }}
  </Form>
);

FormView.propTypes = {
  originalNumber: PropTypes.string,
  dialCodes: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  hasLoadedOriginalData: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
};

FormView.defaultProps = {
  originalNumber: null,
  dialCodes: [],
};

export default FormView;
