import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';

const SendAgainControl = ({ disabled, handleClick }) => (
  <Button
    secondary
    disabled={disabled}
    inverse
    label={t('javascript.twofa.send_code_again.button')}
    {...(disabled ? {} : { onClick: handleClick })}
  />
);

SendAgainControl.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default SendAgainControl;
