import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button } from 'bv-components';
import { useHistory } from 'react-router-dom';
import { moveToPreferences } from 'TwoFactorAuth/helpers';
import { showDisableAllModal } from './helpers/disable_modal.js';

const EnableDisableButton = ({ enabled, reloadTwoFaStatus }) => {
  const history = useHistory();
  const onStatusChangeClick = () => {
    if (enabled) {
      showDisableAllModal(() => {
        reloadTwoFaStatus();
        moveToPreferences();
      });
    } else {
      history.push('/preferences/twofactorauth/enable');
    }
  };

  const buttonText = (isEnabled) => (isEnabled
    ? t('javascript.twofa.settings.toggle_button.disable')
    : t('javascript.twofa.settings.toggle_button.enable')
  );

  return (
    <div className="container-device-manager__container-change-button">
      <Button
        className="container-device-manager__change-button"
        secondary
        inverse={enabled}
        label={buttonText(enabled)}
        onClick={onStatusChangeClick}
      />
    </div>
  );
};

EnableDisableButton.propTypes = {
  enabled: PropTypes.bool.isRequired,
  reloadTwoFaStatus: PropTypes.func.isRequired,
};

export default EnableDisableButton;
