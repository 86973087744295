import { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';
import { showDangerMessage } from 'modal-helper';
import {
  IntroText,
  DotContainer,
  WrongFeedback,
  CodeInput,
  SendAgain,
  SendCodeSpinner,
} from 'SharedComponents/send_code_from_sms';
import { validateCode, sendCode } from 'TwoFactorAuth/api';
import SuccessFeedback from './components/success/success_feedback';
import ChooseLink from './components/choose_link';
import Chooser from './components/chooser';
import SendAgainControl from './components/send_again_control';

import { execCallback } from './helpers/app_store';
import { getValidateEndpoint } from './helpers/common';

const CodeFeedbackView = ({
  loadedMethods, isLogin, twofaMethodsData, onRedirect,
}) => {
  const [status, setStatus] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [retrying, setRetrying] = useState(false);
  const [triesLeft, setTriesLeft] = useState(false);
  const [showWrongFeedback, setShowWrongFeedback] = useState(false);
  const [trustValue, setTrustValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactUsUrl, setContactUsUrl] = useState('');
  const [wasCodeResent, setWasCodeResent] = useState(false);
  const [disableResentButton, setDisableResentButton] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const [brand, setBrand] = useState('');
  const [chooser, setChooser] = useState(false);
  const [currentMethod, setCurrentMethod] = useState();
  const [availableMethods, setAvailableMethods] = useState([]);

  const codeInputRef = createRef();

  const maxCodeLength = 6;

  const handleMethodChange = (data) => {
    setStatus('');
    setCodeValue('');
    setInputValue('');
    setDisabled(false);
    setRetrying(false);
    setTriesLeft(false);
    setShowWrongFeedback(false);
    setCurrentMethod(data.method);
    setAvailableMethods(data.setAvailableMethods);
    setContactUsUrl(data.contactUsUrl || bvVar('contactUsPageUrl'));
    setChooser(false);
  };

  const focusCodeInput = () => {
    if (codeInputRef.current) codeInputRef.current.focus();
  };

  useEffect(() => {
    if (loadedMethods) handleMethodChange(twofaMethodsData);
  }, [loadedMethods]);

  useEffect(() => {
    focusCodeInput();
  });

  const handleCheckboxChange = () => {
    const currentTrustValue = trustValue;
    setTrustValue(!currentTrustValue);
  };

  const handleCodeChange = (value) => {
    if (retrying) {
      setStatus('');
      setRetrying(false);
      // if length is greater than max, code should be cleaned and kept the last char
      return value.length > maxCodeLength ? value.substr(-1, 1) : '';
    }

    return value.substr(0, maxCodeLength);
  };

  const toggleChooser = () => {
    const currentValue = chooser;
    setChooser(!currentValue);
  };

  const handleFeedbackStatus = (response) => {
    setLoading(false);

    if (response.status === 'ok' && response.success) {
      setStatus('success');
      setBrand(response.brand);
    } else if (response.status === 'wrong_code') {
      setStatus('wrong_code');
      setTriesLeft(response.triesLeft);
      setRetrying(true);
      setDisabled(false);
      setShowWrongFeedback(true);
    } else if (response.status === 'failed_2fa') {
      setStatus('code_was_sent_too_many_times');
      setDisabled(true);
      setShowWrongFeedback(true);
      setContactUsUrl(response.contactUsUrl || bvVar('contactUsPageUrl'));
    } else if (response.status === 'no_such_request') {
      // noop;
    } else {
      setStatus('failed');
      setRetrying(true);
      setDisabled(false);
      setShowWrongFeedback(true);
    }
    execCallback(response, isLogin, contactUsUrl);
  };

  const onKeyUp = (event) => {
    if (event.key === 'Backspace' || event.keyCode === 8) {
      setCodeValue(codeValue.length === maxCodeLength ? '' : codeValue.slice(0, -1));
      setStatus(codeValue.length === maxCodeLength ? '' : status);
      setInputValue('');
    }
  };

  const onChange = (event) => {
    if (disabled) {
      return;
    }

    const tmpValue = event.target.value.replace(/\D/g, '');
    const digit = tmpValue.substr(0, 1);
    const code = handleCodeChange(`${codeValue}${digit}`);
    setCodeValue(code);
    setInputValue('');

    if (code.length === maxCodeLength) {
      setDisabled(true);
      setLoading(true);

      validateCode(getValidateEndpoint(isLogin), code, trustValue)
        .then((response) => {
          handleFeedbackStatus(response);
        });
    }
  };

  const enableResendButton = (cooldownInterval) => {
    clearInterval(cooldownInterval);
    setWasCodeResent(false);
    setDisableResentButton(false);
  };

  const sendCodeHandler = () => {
    setLoading(true);
    sendCode(isLogin)
      .then((data) => {
        setLoading(false);
        setWasCodeResent(data.success);
        setDisableResentButton(true);
        setCooldown(data.cooldown);

        const cooldownInterval = setInterval(() => {
          setCooldown(cooldown - 1);
        }, 1000);

        setTimeout(() => { enableResendButton(cooldownInterval); }, data.cooldown * 1000);
        if (data.success === false) {
          showDangerMessage({
            message: t('javascript.twofa.error.general'),
            actions: [
              {
                label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
              },
            ],
          });
        }
      });
  };

  if (status === 'success') {
    return (
      <SuccessFeedback
        login={isLogin}
        method={currentMethod}
        redirect={onRedirect}
        codeValue={codeValue}
        brand={brand}
      />
    );
  }

  if (chooser) {
    return (
      <Chooser
        methods={availableMethods}
        help={contactUsUrl}
        callback={handleMethodChange}
      />
    );
  }

  return (
    <div className="send-verify-code" onClick={focusCodeInput}>
      { (!loadedMethods || loading) && <SendCodeSpinner /> }
      <IntroText
        description={currentMethod && t(`javascript.twofa.code.${currentMethod}.description`)}
        enterCode={currentMethod && t(`javascript.twofa.code.${currentMethod}.enter_code`)}
        showTrustCheckbox={isLogin}
        checked={trustValue}
        onChange={handleCheckboxChange}
        icon={<div className="send-verify-code__header-icon" />}
      />
      <DotContainer code={codeValue} status={status} />
      { showWrongFeedback
        && <WrongFeedback status={status} triesLeft={triesLeft} /> }
      <CodeInput
        ref={codeInputRef}
        maxCodeLength={maxCodeLength}
        value={inputValue}
        handleCodeInputChange={onChange}
        handleCodeInputKeyPress={onKeyUp}
      />
      <SendAgain
        method={currentMethod}
        wasCodeResent={wasCodeResent}
        cooldown={cooldown}
        control={(
          <SendAgainControl
            disabled={disableResentButton}
            handleClick={sendCodeHandler}
          />
        )}
      />
      <ChooseLink
        login={isLogin}
        toggleChooser={toggleChooser}
      />
    </div>
  );
};

CodeFeedbackView.propTypes = {
  twofaMethodsData: PropTypes.shape({
    currentMethod: PropTypes.string,
    availableMethods: PropTypes.arrayOf(PropTypes.string),
    contactUsUrl: PropTypes.string,
  }).isRequired,
  loadedMethods: PropTypes.bool.isRequired,
  isLogin: PropTypes.bool.isRequired,
  onRedirect: PropTypes.bool.isRequired,
};

export default CodeFeedbackView;
