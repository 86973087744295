import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { loggedIn } from 'bv-helpers/session';

import Route from './route';

const UnauthenticatedRoute = ({ children, ...rest }) => (
  <Route {...rest}>
    {loggedIn() ? <Redirect to="/account" /> : children}
  </Route>
);

UnauthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UnauthenticatedRoute;
