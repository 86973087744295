import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalWindow, Spinner } from 'bv-components';
import { useHistory, useLocation } from 'react-router-dom';
import { replaceFragment } from 'bv-helpers/location';
import { useLoggedIn } from 'bv-hooks';
import { t } from 'bv-i18n';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import classNames from 'classnames';
import InsertPassword from 'TwoFactorAuth/components/insert_password';
import {
  doGetTwoFaStatus,
  resetVerifiedPassword as doResetVerifiedPassword,
} from 'TwoFactorAuth/duck';

export default (WrappedComponent, { tKey = 'javascript.twofa.navigation_title.twofa' } = {}) => {
  const WithTwoFaModal = (props) => {
    const {
      hasVerifiedPassword, loadedStatus, getTwoFaStatus, resetVerifiedPassword,
    } = props;
    const loggedIn = useLoggedIn();
    const { pathname } = useLocation();
    const isLoginAttempt = (pathname === '/twofactorauth/login');

    const history = useHistory();

    const getTitle = () => {
      if (!loggedIn) return t('javascript.twofa.navigation_title.twofa');
      if (!hasVerifiedPassword) return t('javascript.twofa.navigation_title.password');
      return t(tKey);
    };

    useEffect(() => {
      if (!isLoginAttempt && !loadedStatus) getTwoFaStatus();
    }, []);

    const renderComponent = () => {
      if (isLoginAttempt) return <WrappedComponent {...props} login />;
      if (!hasVerifiedPassword) return <InsertPassword />;
      if (loadedStatus) return <WrappedComponent {...props} />;
      return <Spinner />;
    };

    const onClose = () => {
      resetVerifiedPassword();
      replaceFragment(isLoginAttempt ? '/login' : '/');
    };

    return (
      <ModalWindow
        title={getTitle()}
        className={classNames('two-factor-modal')}
        scrollToTop
        showBack
        onBack={history.goBack}
        onClose={onClose}
      >
        {renderComponent()}
      </ModalWindow>
    );
  };

  WithTwoFaModal.propTypes = {
    hasVerifiedPassword: PropTypes.string.isRequired,
    loadedStatus: PropTypes.bool.isRequired,
    getTwoFaStatus: PropTypes.func.isRequired,
    resetVerifiedPassword: PropTypes.func.isRequired,
  };

  const mapStateToProps = (state) => ({
    hasVerifiedPassword: state.twoFa.hasVerifiedPassword,
    loadedStatus: state.twoFa.loadedStatus,
  });

  const mapDispatchToProps = (dispatch) => ({
    getTwoFaStatus: compose(dispatch, doGetTwoFaStatus),
    resetVerifiedPassword: compose(dispatch, doResetVerifiedPassword),
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithTwoFaModal);
};
