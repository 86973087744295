import { useEffect, useState, useRef } from 'react';
import { showDangerMessage } from 'modal-helper';
import { t } from 'bv-i18n';
import { useHistory } from 'react-router-dom';
import fetchMobileCountries from 'SharedComponents/personal_details/api/fetch_mobile_number_countries';
import WithTwoFaModal from 'TwoFactorAuth/hocs/with_twofa_modal';
import { getMobileNumber, validateMobileNumberService, send2FACode } from 'TwoFactorAuth/api';
import FormView from './form_view';

const FormContainer = () => {
  const history = useHistory();
  const validatonDebounceTimeout = useRef();

  const [mobileNumber, setMobileNumber] = useState();
  const [allCountries, setAllCountries] = useState([]);
  const [onlyCountries, setOnlyCountries] = useState([]);
  const [dialCodes, setDialCodes] = useState([]);
  const [hasValidatedOriginalNumber, setHasValidatedOriginalNumber] = useState(false);
  const [hasLoadedDialCodes, setHasLoadedDialCodes] = useState(false);

  useEffect(() => {
    getMobileNumber().then(({
      number, onlyCountries: countries, valid,
    }) => {
      setOnlyCountries(countries || []);
      if (valid) setMobileNumber(number);
      setHasValidatedOriginalNumber(true);
    });
  }, []);

  useEffect(() => {
    fetchMobileCountries().then((countries) => {
      setAllCountries(countries);
    });
  }, []);

  useEffect(() => {
    if (allCountries.length && onlyCountries.length) {
      setDialCodes(onlyCountries.map((code) => (
        allCountries.find((c) => c.code.toLowerCase() === code.toLowerCase())
      )));
      setHasLoadedDialCodes(true);
    }
  }, [allCountries, onlyCountries]);

  const validatePhoneNumber = async ({ phone_number: value }) => {
    const { error, valid } = await validateMobileNumberService(value);
    if (valid) return undefined;
    return { phone_number: error };
  };

  const onDebounceValidate = (values) => new Promise((resolve) => {
    if (validatonDebounceTimeout.current) {
      validatonDebounceTimeout.current();
    }

    const timerId = setTimeout(() => {
      resolve(validatePhoneNumber(values));
    }, 500);

    validatonDebounceTimeout.current = () => {
      clearTimeout(timerId);
      resolve(true);
    };
  });

  const onSubmit = async ({ phone_number: value }) => {
    const { error, number } = await validateMobileNumberService(value);
    if (error) return { phone_number: error };

    const reply = await send2FACode(number);
    if (reply && reply.requestId !== undefined) {
      history.push('/preferences/twofactorauth/code');
    } else {
      showDangerMessage({
        message: t('javascript.twofa.error.general'),
        actions: [
          {
            label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
          },
        ],
      });
    }
    return undefined;
  };

  return (
    <FormView
      hasLoadedOriginalData={hasValidatedOriginalNumber && hasLoadedDialCodes}
      originalNumber={mobileNumber}
      dialCodes={dialCodes}
      onSubmit={onSubmit}
      onValidate={onDebounceValidate}
    />
  );
};

export default WithTwoFaModal(FormContainer, { tKey: 'javascript.twofa.navigation_title.mobile_sms_verification' });
