import { Switch } from 'react-router-dom';
import { AuthenticatedRoute, UnauthenticatedRoute } from 'Index/features/routing/components/shared/index';

import DeviceManager from './components/device_manager';
import EnableMethod from './components/enable_method';
import RegisterPhoneNumber from './components/register_phone_number';
import Totp from './components/totp';
import CodeFeedback from './components/code_feedback';

const TwoFAApp = () => (
  <Switch>
    <AuthenticatedRoute
      exact
      path="/preferences/twofactorauth"
      component={DeviceManager}
    />
    <AuthenticatedRoute
      exact
      path="/preferences/twofactorauth/enable"
      component={EnableMethod}
    />
    <AuthenticatedRoute
      exact
      path="/preferences/twofactorauth/enable/totp"
      component={Totp}
    />
    <AuthenticatedRoute
      exact
      path="/preferences/twofactorauth/enable/sms"
      component={RegisterPhoneNumber}
    />
    <AuthenticatedRoute
      exact
      path="/preferences/twofactorauth/code"
      component={CodeFeedback}
    />
    <UnauthenticatedRoute
      exact
      path="/twofactorauth/login"
      component={CodeFeedback}
    />
  </Switch>
);

export default TwoFAApp;
