import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import { useLoggedIn } from 'bv-hooks';

import Route from './route';

const AuthenticatedRoute = ({ children, ...rest }) => {
  const loggedIn = useLoggedIn();
  const location = useLocation();

  return (
    <Route {...rest}>
      {loggedIn ? children : (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      )}
    </Route>
  );
};

AuthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticatedRoute;
