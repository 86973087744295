import PropTypes from 'prop-types';
import { useFeature } from 'bv-hooks';

const ComponentFlipper = ({ feature, enabledComponent, disabledComponent }) => {
  const [featureEnabled, featureResolved] = useFeature(feature);
  if (!featureResolved) return null;

  return featureEnabled ? enabledComponent : disabledComponent;
};

ComponentFlipper.propTypes = {
  feature: PropTypes.string.isRequired,
  enabledComponent: PropTypes.node.isRequired,
  disabledComponent: PropTypes.node.isRequired,
};

export default ComponentFlipper;
