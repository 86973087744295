import {
  getTwofaStatus, getTwofaMethods, getTwoFaAppInfo, getTwoFaQrCode,
} from './api';

export const SET_VERIFIED_PASSWORD = 'twofa/SET_VERIFIED_PASSWORD';
export const RESET_VERIFIED_PASSWORD = 'twofa/RESET_VERIFIED_PASSWORD';
export const SET_LOADED_TWOFA_STATUS = 'twofa/SET_LOADED_TWOFA_STATUS';
export const SET_LOADING_TWOFA_STATUS = 'twofa/SET_LOADING_TWOFA_STATUS';
export const SET_LOADED_TWOFA_METHODS = 'twofa/SET_LOADED_TWOFA_METHODS';
export const SET_LOADING_TWOFA_METHODS = 'twofa/SET_LOADING_TWOFA_METHODS';
export const SET_LOADING_TWOFA_APP_INFO = 'twofa/SET_LOADING_TWOFA_APP_INFO';
export const SET_LOADED_TWOFA_APP_INFO = 'twofa/SET_LOADED_TWOFA_APP_INFO';
export const SET_LOADING_TWOFA_QR_CODE = 'twofa/SET_LOADING_TWOFA_QR_CODE';
export const SET_LOADED_TWOFA_QR_CODE = 'twofa/SET_LOADED_TWOFA_QR_CODE';
export const SET_RELOAD_TWOFA_STATUS = 'twofa/SET_RELOAD_TWOFA_STATUS';

export const setVerifiedPassword = () => ({
  type: SET_VERIFIED_PASSWORD,
});

export const resetVerifiedPassword = () => ({
  type: RESET_VERIFIED_PASSWORD,
});

export const setLoadedTwoFaStatus = (payload) => ({
  type: SET_LOADED_TWOFA_STATUS,
  payload,
});

export const setLoadingTwoFaStatus = () => ({
  type: SET_LOADING_TWOFA_STATUS,
});

export const setLoadedTwoFaMethods = (payload) => ({
  type: SET_LOADED_TWOFA_METHODS,
  payload,
});

export const setLoadingTwoFaMethods = () => ({
  type: SET_LOADING_TWOFA_METHODS,
});

export const setLoadedTwoFaAppInfo = (payload) => ({
  type: SET_LOADED_TWOFA_APP_INFO,
  payload,
});

export const setLoadingTwoFaAppInfo = () => ({
  type: SET_LOADING_TWOFA_APP_INFO,
});

export const setLoadedTwoFaQrCode = (payload) => ({
  type: SET_LOADED_TWOFA_QR_CODE,
  payload,
});

export const setLoadingTwoFaQrCode = () => ({
  type: SET_LOADING_TWOFA_QR_CODE,
});

export const setReloadTwoFaStatus = () => ({
  type: SET_RELOAD_TWOFA_STATUS,
});

export const doGetTwoFaStatus = () => (dispatch) => {
  setLoadingTwoFaStatus();
  return getTwofaStatus()
    .then((result) => {
      dispatch(setLoadedTwoFaStatus(result));
    });
};

export const doGetTwoFaMethods = () => (dispatch) => {
  setLoadingTwoFaMethods();
  return getTwofaMethods()
    .then((result) => {
      dispatch(setLoadedTwoFaMethods(result));
    });
};

export const doGetTwoFaAppInfo = () => (dispatch) => {
  setLoadingTwoFaAppInfo();
  return getTwoFaAppInfo()
    .then((result) => {
      dispatch(setLoadedTwoFaAppInfo(result));
    });
};

export const doGetTwoFaQRCode = () => (dispatch) => {
  setLoadingTwoFaQrCode();
  return getTwoFaQrCode()
    .then((result) => {
      dispatch(setLoadedTwoFaQrCode(result));
    });
};

const initialState = {
  hasVerifiedPassword: false,
  enabled: false,
  recommendation: null,
  loadingStatus: false,
  loadedStatus: false,
  loadingMethods: false,
  loadedMethods: false,
  loadingAppInfo: false,
  loadedAppInfo: false,
  loadingQrCode: false,
  loadedQrCode: false,
  qrCode: {},
  appInfo: {},
  methods: [],
  twofaMethodsData: {
    currentMethod: '',
    availableMethods: [],
    contactUsUrl: '',
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_VERIFIED_PASSWORD: {
      return {
        ...state,
        hasVerifiedPassword: true,
      };
    }

    case RESET_VERIFIED_PASSWORD: {
      return {
        ...state,
        hasVerifiedPassword: false,
      };
    }

    case SET_LOADING_TWOFA_STATUS: {
      return {
        ...state,
        loadingStatus: true,
      };
    }
    case SET_LOADED_TWOFA_STATUS: {
      return {
        ...state,
        ...action.payload,
        enabled: action.payload.active,
        loadingStatus: false,
        loadedStatus: true,
      };
    }
    case SET_RELOAD_TWOFA_STATUS: {
      return {
        ...state,
        loadedStatus: false,
      };
    }

    case SET_LOADING_TWOFA_METHODS: {
      return {
        ...state,
        loadingMethods: true,
      };
    }
    case SET_LOADED_TWOFA_METHODS: {
      return {
        ...state,
        twofaMethodsData: action.payload,
        loadingMethods: false,
        loadedMethods: true,
      };
    }

    case SET_LOADING_TWOFA_APP_INFO: {
      return {
        ...state,
        loadingAppInfo: true,
      };
    }
    case SET_LOADED_TWOFA_APP_INFO: {
      return {
        ...state,
        appInfo: action.payload,
        loadingAppInfo: false,
        loadedAppInfo: true,
      };
    }

    case SET_LOADING_TWOFA_QR_CODE: {
      return {
        ...state,
        loadingQrCode: true,
      };
    }
    case SET_LOADED_TWOFA_QR_CODE: {
      return {
        ...state,
        qrCode: action.payload,
        loadingQrCode: false,
        loadedQrCode: true,
      };
    }

    default:
      return state;
  }
};
