import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isObject } from 'underscore';
import { t } from 'bv-i18n';
import { moveToEnableSmsScreen } from 'TwoFactorAuth/helpers';
import { getMobileNumber } from 'TwoFactorAuth/api';
import StatusCard from './status_card';
import { showDisableSmsModal } from '../helpers/disable_modal';

const Sms = ({ enabled, update }) => {
  const [mobileNumber, setMobileNumber] = useState('');

  useEffect(() => {
    if (enabled) {
      getMobileNumber().then((reply) => {
        if (reply && reply.number) {
          const displayNumber = isObject(reply.number) ? (
            reply.number.full_number || reply.number.number
          ) : reply.number;
          setMobileNumber(displayNumber);
        }
      });
    }
  }, []);

  const showModal = () => {
    showDisableSmsModal(update);
  };

  if (enabled == null) {
    return null;
  }

  return (
    <StatusCard
      enabled={enabled}
      iconClass="twofa-status__icon-sms"
      enabledInfo={mobileNumber}
      enabledTitle={t('javascript.twofa.settings.device_manager.status.sms.enabled.title')}
      disabledTitle={t('javascript.twofa.settings.device_manager.status.sms.disabled.title')}
      disabledSubtitle={t('javascript.twofa.settings.device_manager.status.sms.disabled.subtitle')}
      addCb={moveToEnableSmsScreen}
      editCb={moveToEnableSmsScreen}
      removeCb={showModal}
    />
  );
};

Sms.propTypes = {
  enabled: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
};
export default Sms;
