import { t } from 'bv-i18n';
import StatusCard from './status_card';

const Disabled = () => (
  <StatusCard
    iconClass="disabled"
    enabledTitle={t('javascript.twofa.settings.device_manager.status.totp.enabled.title')}
    disabledTitle={t('javascript.twofa.settings.device_manager.disabled_title')}
  />
);

export default Disabled;
