import { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { useHistory } from 'react-router-dom';
import { Button } from 'bv-components';
import { SendCodeSpinner } from 'SharedComponents/send_code_from_sms';
import ManualBlock from './manual_block';

const Scan = ({ secret, qr, email }) => {
  const history = useHistory();
  const [manual, setManuel] = useState(false);

  const showManual = () => setManuel(true);

  return (
    <div className="bvs-card twofa-totp-scan twofa__container">
      <div className="twofa-totp-intro-header">
        <div className="twofa-totp-intro__icon" />
        <p className="twofa-totp-intro__intro-text">
          {t('javascript.twofa.settings.totp.scan.header')}
        </p>
      </div>
      <hr />
      { secret === ''
        ? (
          <SendCodeSpinner />
        ) : (
          <div className="twofa-totp-scan-body">
            <img className="twofa-totp-scan-body__qr" alt="QR code" src={qr} />
            <div className={`twofa-totp-scan-body-buttons ${manual ? 'twofa-totp-scan-body-buttons--manual_code' : ''}`}>
              <Button
                className="twofa-totp-scan-body-buttons__next-button"
                primary
                onClick={() => history.push('/preferences/twofactorauth/code')}
                label={t('javascript.twofa.settings.totp.scan.next_button')}
              />
              <ManualBlock
                open={manual}
                showManual={showManual}
                email={email}
                code={secret}
              />
            </div>
          </div>
        )}
    </div>
  );
};

Scan.propTypes = {
  secret: PropTypes.string,
  qr: PropTypes.string,
  email: PropTypes.string,
};

Scan.defaultProps = {
  secret: '',
  qr: '',
  email: '',
};

export default Scan;
