import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';

const StatusCard = ({
  iconClass,
  enabled,
  enabledTitle,
  disabledTitle,
  disabledSubtitle,
  enabledInfo,
  addCb,
  editCb,
  removeCb,
}) => (
  <>
    <div className="twofactor-status-container">
      <div className="twofa-status__vertical-column twofa-status__icon-column">
        {iconClass === 'disabled'
          ? <Icon id="lock-disabled" className="twofa-status__icon-lock" />
          : <i className={`bvs-icon twofa-status__icon ${iconClass}`} />}
      </div>
      <div className="twofa-status__vertical-column twofa-status__number-column">
        <label htmlFor="switch_showbalance">
          {enabled ? enabledTitle : disabledTitle}
        </label>
        {!enabled && (
        <label htmlFor="switch_showbalance">
          {disabledSubtitle}
        </label>
        )}
        {enabled && (
        <div className="twofa-status-enabled">
          <span className="twofa-status-enabled__label bvs-text-primary-light">
            {t('javascript.twofa.settings.device_manager.status.enabled')}
          </span>
          <span>{enabledInfo}</span>
        </div>
        )}
      </div>
      <div className="twofa-status__vertical-column twofa-status__action-column">
        {!enabled && addCb && (
        <i
          className="bvs-icon twofa-status__icon-button twofa-status__icon-add"
          onClick={addCb}
        />
        )}
        {enabled && editCb && (
        <i
          className="bvs-icon twofa-status__icon-button twofa-status__icon-edit"
          onClick={editCb}
        />
        )}
        {enabled && removeCb && (
        <i
          className="bvs-icon twofa-status__icon-button twofa-status__icon-remove"
          onClick={removeCb}
        />
        )}
      </div>
    </div>
    <hr />
  </>
);

StatusCard.propTypes = {
  enabled: PropTypes.bool,
  enabledInfo: PropTypes.string,
  enabledTitle: PropTypes.string.isRequired,
  disabledTitle: PropTypes.string.isRequired,
  disabledSubtitle: PropTypes.string,
  iconClass: PropTypes.string.isRequired,
  addCb: PropTypes.func,
  editCb: PropTypes.func,
  removeCb: PropTypes.func,
};

StatusCard.defaultProps = {
  enabled: false,
  enabledInfo: '',
  disabledSubtitle: '',
  addCb: undefined,
  editCb: undefined,
  removeCb: undefined,
};

export default StatusCard;
