import { t } from 'bv-i18n';
import { showDangerMessage } from 'modal-helper';
import { redirectToHome, redirectToSettings, continueLogin } from './common';

const { removeModal } = window.reduxState.store.actionDispatchers.modal;

const handleEnableFailed = (response) => {
  if (response.triesLeft <= 0) {
    showDangerMessage({
      title: t('javascript.twofa.error.failed_sms_many_times'),
      iconClosable: true,
      icon: false,
      onClose: redirectToHome,
      actions: [
        {
          label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
        },
      ],
      dataLayer: {
        error_message: t('javascript.twofa.error.failed_sms_many_times'),
        error_key: 'javascript.twofa.error.failed_sms_many_times',
        error_code: 'E0x006',
      },
    });
  }
};

const showVerificationFailedMessage = (contactUsUrl) => {
  removeModal();
  showDangerMessage({
    title: t('javascript.twofa.login_suspended.alert_title'),
    children: (
      <p className="bvs-text-error">
        { t('javascript.twofa.login_suspended.alert_description') }
      </p>),
    iconClosable: true,
    icon: false,
    onClose: redirectToHome,
    className: 'twofa__code--feedback-modal-failed',
    actions: [{
      label: (
        <div>
          <span className="bvs-icon twofa-icon-contact-us" />
          { t('javascript.twofa.login_suspended.alert_cta') }
        </div>
      ),
      className: 'two_factor_contact_support',
      close: false,
      onClick: () => {
        removeModal();
        window.location.href = contactUsUrl;
      },
    }],
    dataLayer: {
      error_title: t('javascript.twofa.login_suspended.alert_title'),
      error_message: t('javascript.twofa.login_suspended.alert_description'),
      error_key: 'javascript.twofa.login_suspended.alert_description',
      error_code: 'E0x007',
    },
  });
};

export const execCallback = (response, login, contactUsUrl) => {
  if (response.status === 'ok' && response.success) {
    if (login) {
      setTimeout(() => { continueLogin(response); }, 500);
    }
  } else if (response.status === 'code_was_sent_too_many_times' && login) {
    showVerificationFailedMessage(contactUsUrl);
  } else if (response.status === 'code_was_sent_too_many_times') {
    handleEnableFailed(response);
  } else if (response.status === 'missing_geo_location') {
    showDangerMessage({
      title: response.message,
      iconClosable: true,
      icon: false,
      actions: [
        {
          label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
        },
      ],
      dataLayer: {
        error_message: response.message,
        error_key: 'login_error.login_service.ls_035',
        error_code: 'E0x016',
      },
    });
  } else if (response.status === 'no_such_request') {
    showDangerMessage({
      title: t('javascript.twofa.error.general'),
      iconClosable: true,
      icon: false,
      onClose: redirectToSettings,
      actions: [
        {
          label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
        },
      ],
      dataLayer: {
        error_message: t('javascript.twofa.error.general'),
        error_key: 'javascript.twofa.error.general',
        error_code: 'E0x008',
      },
    });
  }
};
