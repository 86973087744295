import { getJSON, postJSON } from 'bv-fetch';

export const getTwofaMethods = () => (
  getJSON('/bv_api/two_factor_auth/status/methods').catch(() => {})
);

export const setTwofaMethod = (method) => (
  postJSON('/bv_api/two_factor_auth/status/methods', { method })
);

export const validateCode = (endpoint, code, trustValue) => (
  postJSON(endpoint, { code, trustValue })
);

export const sendCode = (login) => (
  postJSON('/bv_api/twofactorauth/sendcode', { login })
);

export const getTwofaStatus = () => (
  getJSON('/bv_api/two_factor_auth/status').catch(() => {})
);

export const getTwoFaAppInfo = () => (
  getJSON('/bv_api/two_factor_auth/totp/app_info').catch(() => {})
);

export const getTwoFaQrCode = () => (
  getJSON('/bv_api/two_factor_auth/totp/qr_code').catch(() => {})
);

export const checkPassword = (passwordValue) => (
  postJSON('/bv_api/twofactorauth/check_password', { password: passwordValue }).catch(() => {})
);

export const getMobileNumber = () => (
  getJSON('/bv_api/twofactorauth/get_mobile_number').catch(() => {})
);

export const fetchTrustedDevicesList = () => (
  getJSON('/bv_api/twofactorauth/get_trusted_devices_list').catch(() => {})
);

export const untrustDevice = (devId) => (
  postJSON('/bv_api/twofactorauth/untrust_device', { deviceId: devId }).catch(() => {})
);

export const untrustAllDevices = () => (
  postJSON('/bv_api/twofactorauth/untrust_all_devices', {}).catch(() => {})
);

export const disable2FA = (method) => (
  postJSON('/bv_api/twofactorauth/disable', { method }).catch(() => {})
);

export const validateMobileNumberService = (number) => (
  postJSON('/bv_api/twofactorauth/validate_mobile_number', number).catch(() => {})
);

export const send2FACode = (number) => (
  postJSON('/bv_api/twofactorauth/enable_send_code', { mobileNumber: number }).catch(() => {})
);
