import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { doGetTwoFaStatus } from 'TwoFactorAuth/duck';
import { getEnabledTotp, getEnabledSms } from 'TwoFactorAuth/selectors';
import Totp from './totp';
import Sms from './sms';
import Disabled from './disabled';

const StatusContainer = ({ enabledTotp, enabledSms, getTwoFaStatus }) => {
  if (!enabledTotp && !enabledSms) {
    return <Disabled />;
  }

  return (
    <>
      <Totp enabled={enabledTotp} update={getTwoFaStatus} />
      <Sms enabled={enabledSms} update={getTwoFaStatus} />
    </>
  );
};

StatusContainer.propTypes = {
  enabledTotp: PropTypes.bool.isRequired,
  enabledSms: PropTypes.bool.isRequired,
  getTwoFaStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  enabledTotp: getEnabledTotp(state),
  enabledSms: getEnabledSms(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTwoFaStatus: compose(dispatch, doGetTwoFaStatus),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusContainer);
