import { createSelector } from 'reselect';

const getTwoFaMethods = (state) => state.twoFa.methods;

export const getEnabledTotp = createSelector(
  getTwoFaMethods, (methods) => (methods.find((m) => m.type === 'totp')?.active),
);

export const getEnabledSms = createSelector(
  getTwoFaMethods, (methods) => (methods.find((m) => m.type === 'sms')?.active),
);

export const getTwoFaEnabled = createSelector(
  getTwoFaMethods, (methods) => methods.some((m) => !!m.active),
);
