import PropTypes from 'prop-types';
import { t, locale } from 'bv-i18n';
import classNames from 'classnames';
import { showWarningMessage } from 'modal-helper';

const Device = ({
  id,
  onConfirmDeviceRemove,
  lastSeen,
  currentDeviceId,
  operatingSystem,
  hardware,
}) => {
  const confirmRemoveDevice = () => {
    showWarningMessage({
      title: t('javascript.twofa.settings.device_remove.alert.title'),
      actions: [
        {
          label: t('javascript.twofa.settings.device_remove.alert.cta_accept'),
          id: 'yes-btn',
          warning: true,
          onClick: onConfirmDeviceRemove.bind(this, id),
        },
        {
          label: t('javascript.twofa.settings.device_remove.alert.cta_cancel'),
          id: 'no-btn',
          inverse: true,
          warning: true,
          close: true,
        },
      ],
    });
  };

  const lastLoginTime = new Date(lastSeen);

  const { name: operatingSystemName } = operatingSystem;
  const { name, type } = hardware;

  const deviceType = type.trim().toUpperCase();
  const deviceClassName = classNames({
    'bvs-icon': true,
    'single-device__icon': true,
    'device-type__tablet': deviceType === 'TABLET',
    'device-type__phone': deviceType === 'MOBILE BROWSER' || deviceType === 'PHONE',
    'device-type__desktop': deviceType === 'BROWSER',
    'device-type__unknown': deviceType !== 'TABLET' && deviceType !== 'MOBILE BROWSER'
                              && deviceType !== 'PHONE' && deviceType !== 'BROWSER',
  });

  return (
    <div className="single-device-container">
      <div>
        <i className={deviceClassName} />
      </div>
      <div className="single-device__labels-column">
        <label htmlFor="switch_showbalance" className="single-device__device-name single-device__label">
          {`${name} - ${operatingSystemName}`}
          {id === currentDeviceId && ` (${t('javascript.twofa.settings.device.current_title')})`}
        </label>
        <label htmlFor="switch_showbalance" className="single-device__label" id="2fa_status_label device_last_login">
          {t('javascript.twofa.settings.device_last_login')}
            &nbsp;
          {lastLoginTime.toLocaleString(locale())}
        </label>
      </div>
      <div className="single-device__remove-icon-column">
        <i className="bvs-icon single-device__remove-icon" onClick={confirmRemoveDevice} />
      </div>
    </div>
  );
};

Device.propTypes = {
  id: PropTypes.string.isRequired,
  lastSeen: PropTypes.number.isRequired,
  currentDeviceId: PropTypes.string.isRequired,
  onConfirmDeviceRemove: PropTypes.func.isRequired,
  operatingSystem: PropTypes.instanceOf(Object).isRequired,
  hardware: PropTypes.instanceOf(Object).isRequired,
};

export default Device;
