import { t } from 'bv-i18n';
import { showDangerMessage, showWarningMessage } from 'modal-helper';
import { disable2FA } from 'TwoFactorAuth/api';

const { removeModal } = window.reduxState.store.actionDispatchers.modal;

const showDisableModal = (method, successCb, message) => {
  const disable = () => {
    removeModal();
    disable2FA(method).then((reply) => {
      if (reply && !reply.error) {
        successCb();
      } else {
        showDangerMessage({
          title: t('javascript.twofa.error.general'),
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
            },
          ],
          dataLayer: {
            error_message: t('javascript.twofa.error.general'),
            error_key: 'javascript.twofa.error.general',
            error_code: 'E0x00D',
          },
        });
      }
    });
  };

  showWarningMessage({
    title: t('javascript.twofa.settings.toggle_button.disable'),
    message,
    actions: [
      {
        label: t('javascript.twofa.settings.device_remove.alert.cta_accept'), id: 'yes-btn', warning: true, onClick: disable,
      },
      {
        label: t('javascript.twofa.settings.device_remove.alert.cta_cancel'), id: 'no-btn', inverse: true, warning: true, close: true,
      },
    ],
    dataLayer: {
      error_title: t('javascript.twofa.settings.toggle_button.disable'),
      error_message: message,
      error_key: message,
      error_code: 'W0x004',
    },
  });
};

export const showDisableSmsModal = (successCb) => (
  showDisableModal(
    'sms',
    successCb,
    t('javascript.twofa.settings.device_manager.status.sms.warning'),
  ));
export const showDisableTotpModal = (successCb) => (
  showDisableModal(
    'totp',
    successCb,
    t('javascript.twofa.settings.device_manager.status.totp.warning'),
  ));
export const showDisableAllModal = (successCb) => (
  showDisableModal(
    'all',
    successCb,
    t('javascript.twofa.settings.disable_confirmation'),
  ));
