import { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';

const TotpButton = (props) => (
  <Button
    className="twofa-totp-scan__show-button"
    secondary
    {...props}
  />
);

const ManualBlock = ({
  open, showManual, email, code,
}) => {
  const [copied, setCopied] = useState(false);
  const codeText = createRef();
  let copyFeedbackTimeout = createRef();

  const copyCode = () => {
    codeText.current.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    setCopied(true);

    if (!copyFeedbackTimeout) {
      copyFeedbackTimeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  const isIosDevice = () => (window.navigator && window.navigator.platform && /iP(ad|hone|od)/.test(window.navigator.platform));

  if (!open) {
    return (
      <TotpButton
        inverse
        onClick={showManual}
        label={t('javascript.twofa.settings.totp.scan.manual_button')}
      />
    );
  }

  return (
    <div className="twofa-totp-scan-code-opened">

      <span className="twofa-totp-scan-code-opened__account-label">{t('javascript.twofa.settings.totp.scan.account_label')}</span>
      {' '}
      <span className="twofa-email">{email}</span>

      <div className="twofa-totp-scan-code-opened-code">
        <textarea ref={codeText} readOnly className="twofa-totp-scan-code-opened-code__text">
          {code}
        </textarea>
        {copied && (
          <div className="twofa-totp-scan-code-opened-code__overlay">
            <p>{t('javascript.twofa.settings.totp.scan.copy_feedback')}</p>
          </div>
        )}
      </div>
      {!isIosDevice() && (
      <TotpButton
        onClick={copyCode}
        label={t('javascript.twofa.settings.totp.scan.copy_button')}
      />
      )}
    </div>
  );
};

ManualBlock.propTypes = {
  open: PropTypes.bool.isRequired,
  showManual: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default ManualBlock;
