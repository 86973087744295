import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'underscore';
import WithTwoFaModal from 'TwoFactorAuth/hocs/with_twofa_modal';
import { redirectToLogin } from 'TwoFactorAuth/helpers';
import { getTwoFaEnabled } from 'TwoFactorAuth/selectors';
import { setReloadTwoFaStatus } from 'TwoFactorAuth/duck';
import StatusContainer from './status/status_container';
import DeviceList from './devicelist';
import Description from './description';
import EnableDisableButton from './enable_disable_button';

const Home = ({ enabled, reloadTwoFaStatus }) => (
  <div className="twofa__container">
    <div className="bvs-card container-device-manager">
      <div className="container-device-manager__header">
        <div className="container-device-manager__phone-lock-icon" />
        <Description enabled={enabled} />
      </div>
      <hr />
      <StatusContainer />
      <DeviceList
        enabled={enabled}
        removeDevicesCallback={redirectToLogin}
        currentDeviceRemovedCallback={redirectToLogin}
      />
      <EnableDisableButton
        enabled={enabled}
        reloadTwoFaStatus={reloadTwoFaStatus}
      />
    </div>
  </div>
);

Home.propTypes = {
  enabled: PropTypes.bool.isRequired,
  reloadTwoFaStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  enabled: getTwoFaEnabled(state),
});

const mapDispatchToProps = (dispatch) => ({
  reloadTwoFaStatus: compose(dispatch, setReloadTwoFaStatus),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithTwoFaModal(Home));
