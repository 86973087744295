import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Button, Icon } from 'bv-components';

const Method = ({
  title, topics, enableCb, iconClass, recommended,
}) => (
  <div className={`twofa-enable-method ${recommended ? 'twofa-enable-method--recommended' : ''}`}>
    <div className="twofa-enable-method__info-column">
      <div className="twofa-enable-method__header">
        <div className={`bvs-icon twofa-enable-method__icon ${iconClass}`} />
        <div className={`bvs-h5 twofa-enable-method__title ${recommended ? 'twofa-enable-method__title--recommended' : ''}`}>
          {title}
        </div>
      </div>
      <div className={`twofa-enable-method-topics ${recommended ? 'twofa-enable-method-topics--recommended' : ''}`}>
        {topics.map((item) => (
          <div className="twofa-enable-method-topics-item">
            <Icon success className="twofa-enable-method-topics-item__icon" />
            <span className="twofa-enable-method-topics-item__text">{item}</span>
          </div>
        ))}
      </div>
    </div>
    <div className="twofa-enable-method__button-column">
      <Button
        className="twofa-enable-method__button"
        secondary
        inverse={!recommended}
        label={t('javascript.twofa.settings.enable_method.button')}
        onClick={enableCb}
      />
    </div>
  </div>
);

Method.propTypes = {
  title: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.string).isRequired,
  enableCb: PropTypes.func.isRequired,
  iconClass: PropTypes.string.isRequired,
  recommended: PropTypes.bool,
};

Method.defaultProps = {
  recommended: false,
};

export default Method;
