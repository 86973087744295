import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { moveToEnableTotpScreen } from 'TwoFactorAuth/helpers';
import StatusCard from './status_card';
import { showDisableTotpModal } from '../helpers/disable_modal';

const Totp = ({ update, enabled }) => {
  const showModal = () => {
    showDisableTotpModal(update);
  };

  if (enabled == null) {
    return null;
  }

  return (
    <StatusCard
      enabled={enabled}
      iconClass="twofa-status__icon-totp"
      enabledTitle={t('javascript.twofa.settings.device_manager.status.totp.enabled.title')}
      disabledTitle={t('javascript.twofa.settings.device_manager.status.totp.disabled.title')}
      disabledSubtitle={t('javascript.twofa.settings.device_manager.status.totp.disabled.subtitle')}
      addCb={moveToEnableTotpScreen}
      removeCb={showModal}
    />
  );
};

Totp.propTypes = {
  enabled: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
};

export default Totp;
