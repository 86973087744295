import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { showSuccessMessage, showWarningMessage, showDangerMessage } from 'modal-helper';
import { redirectToLogin } from 'TwoFactorAuth/helpers';
import { fetchTrustedDevicesList, untrustDevice, untrustAllDevices } from 'TwoFactorAuth/api';
import Device from './device';

const { removeModal } = window.reduxState.store.actionDispatchers.modal;

const DeviceList = ({ enabled }) => {
  const [devicesList, setDevicesList] = useState([]);
  const [currentDeviceId, setCurrentDeviceId] = useState('');

  const getTrustedDevices = () => {
    fetchTrustedDevicesList()
      .then((response) => {
        if (response && response.trustedDevices) {
          setDevicesList(response.trustedDevices);
          setCurrentDeviceId(response.currentDeviceId);
        } else {
          showDangerMessage({
            title: t('javascript.twofa.error.general'),
            actions: [
              {
                label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
              },
            ],
            dataLayer: {
              error_message: t('javascript.twofa.error.general'),
              error_key: 'javascript.twofa.error.general',
              error_code: 'E0x00A',
            },
          });
        }
      });
  };

  const removeAllDevices = () => {
    removeModal();
    untrustAllDevices().then((response) => {
      if (response && response.success) {
        showSuccessMessage({
          title: t('javascript.twofa.settings.all_devices_remove.removed_successfully'),
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', inverse: true, primary: true, close: true,
            },
          ],
          onClose: redirectToLogin,
        });
      } else if (response && response.devices_removed) {
        getTrustedDevices();
        showDangerMessage({
          title: 'twofa.error.devices_removed_error_on_logout',
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
            },
          ],
          dataLayer: {
            error_message: t('twofa.error.devices_removed_error_on_logout'),
            error_key: 'twofa.error.devices_removed_error_on_logout',
            error_code: 'E0x00B',
          },
        });
      } else {
        showDangerMessage({
          title: t('javascript.twofa.error.general'),
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
            },
          ],
          dataLayer: {
            error_message: t('javascript.twofa.error.general'),
            error_key: 'javascript.twofa.error.general',
            error_code: 'E0x00C',
          },
        });
      }
    });
  };

  useEffect(() => {
    if (enabled) {
      getTrustedDevices();
    }
  }, []);

  const onDeviceRemove = (deviceId) => {
    removeModal();
    untrustDevice(deviceId).then((response) => {
      if (response && response.success && response.device_removed) {
        showSuccessMessage({
          title: t('javascript.twofa.settings.device_remove.current_removed'),
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', inverse: true, primary: true, close: true,
            },
          ],
          onClose: redirectToLogin,
        });
      } else if (response && response.success) {
        getTrustedDevices();
      } else {
        showDangerMessage({
          title: t('javascript.twofa.error.general'),
          actions: [
            {
              label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
            },
          ],
        });
      }
    });
  };

  const onRemoveAllClick = () => {
    showWarningMessage({
      title: t('javascript.twofa.settings.all_devices_remove.alert.title'),
      message: t('javascript.twofa.settings.all_devices_remove.alert.description'),
      actions: [
        {
          label: t('javascript.twofa.settings.all_devices_remove.alert.cta_accept'), id: 'yes-btn', warning: true, onClick: removeAllDevices,
        },
        {
          label: t('javascript.twofa.settings.all_devices_remove.alert.cta_cancel'), id: 'no-btn', inverse: true, warning: true, close: true,
        },
      ],
    });
  };

  const renderDevices = () => devicesList.map((device) => (
    <Device
      key={device.id}
      {...device}
      onConfirmDeviceRemove={onDeviceRemove}
      currentDeviceId={currentDeviceId}
    />
  ));

  if (!enabled || devicesList.length === 0) {
    return null;
  }

  return (
    <div className="device-list-container">
      <div className="header-container">
        <p className="header-container__title">{t('javascript.twofa.settings.device_management')}</p>
        { devicesList.length > 0 && (
        <p className="header-container__remove-all-button" onClick={onRemoveAllClick}>
          {t('javascript.twofa.settings.all_devices_remove.label')}
        </p>
        ) }
        <hr className="device-list__top-horizontal-bar" />
      </div>
      <div className="device-list__main-list">
        { renderDevices() }
      </div>
      <hr className="horizontal-bar device-list__bottom-horizontal-bar" />
    </div>
  );
};

DeviceList.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default DeviceList;
